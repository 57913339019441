import React from 'react';
import './styles/App.scss';
import PhoneAdvertisement from "./pages/PhoneAdvertisement";

export default function App() {
  return (
    <div className="App">
      <PhoneAdvertisement />
    </div>
  );
}
